<template>
  <div class="container">
    <div class="content">
      <el-button class="add-group__button" type="primary" @click="addTagsGroup">新增分组</el-button>
      <el-table
        :data="list"
        :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
        <!-- 分组名称 -->
        <el-table-column prop="title" label="分组名称" width="200"></el-table-column>

        <!-- 标签内容 -->
        <el-table-column label="标签">
          <template slot-scope="scope">
            <el-tag class="tags-item" type="info" 
              v-for="(tag, index) in scope.row.config" 
              :closable="scope.row.type != 'system'"
              :key="index"
              @close="delTags(scope.row, tag)">{{tag}}</el-tag>
            <el-button v-if="scope.row.type != 'system'" type="text" style="color: #999"
              @click="addTags(scope.row)">+ 新增标签</el-button>
          </template>
        </el-table-column>

        <!-- 分组操作 -->
        <el-table-column label="分组操作" width="140" align-text="right">
          <template slot-scope="scope">
            <template v-if="scope.row.type != 'system'">
              <el-button @click="updateTagsGroup(scope.row)" type="text" style="color: #ff0000"
                class="add-group">编辑</el-button>
              <el-button @click="delTagsGroup(scope.row)" type="text"
                class="add-group">删除</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import tagsTemplate from '@/websites/cms/js/template/Tags'
export default {
  data(){
    return {
      id: '',
      title: '',
      list: []
    }
  },
  created(){
    this.getTagsGroup();
  },
  methods: {
    // 添加标签
    async addTags(item){
      let dialog = await this.$prompt('请输入内容', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.{2,10}?$/,
        inputErrorMessage: '标签只能在2-10个字符之间'
      });
      if(dialog.action != 'confirm') return;
      item.config.push(dialog.value);
      let res = await this.$ZGManager.updateMaterial(item.id, {
        title: item.title,
        configs: JSON.stringify(item.config)
      });
      if(res.status == 200){
        this.getTagsGroup();
      }
    },

    // 删除标签
    async delTags(item, tag){
      let dialog = await this.$confirm(`此操作将删除“${tag}”, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(dialog != 'confirm') return;
      let index = item.config.findIndex(e => e == tag);
      if(index == -1) return;
      item.config.splice(index, 1);
      let res = await this.$ZGManager.updateMaterial(item.id, {
        title: item.title,
        configs: JSON.stringify(item.config)
      });
      if(res.status == 200){
        this.getTagsGroup();
      }
    },

    // 获取服务器标签
    async getTagsGroup(){
      this.list = [];
      tagsTemplate.map(e => this.list.push(e));
      let res = await this.$ZGManager.getMaterialList({type: 61, pageNumber: 100});
      if(res.status == 200){
        res.data.list.map((e) => {
          e.config = JSON.parse(e.configs) || [];
          this.list.push(e);
        });
      }
    },

    // 添加素材分组
    async addTagsGroup(){
      let dialog = await this.$prompt('请输入分组标题', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.{2,10}?$/,
        inputErrorMessage: '标题只能在2-10个字符之间'
      });
      if(dialog.action != 'confirm') return;
      let res = await this.$ZGManager.addMaterial({
        type: 61,
        title: dialog.value,
      });
      if(res.status == 200){
        this.$message.success('添加成功');
        this.getTagsGroup();
      }
    },

    // 删除素材分组
    async delTagsGroup(item){
      let dialog = await this.$confirm(`此操作将永久删除“${item.title}”, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(dialog != 'confirm') return;
      let res = await this.$ZGManager.updateMaterialStatus(item.id, -1);
      if(res.status == 200){
        this.$message.success('删除成功');
        this.getTagsGroup();
      }
    },
    
    // 修改素材分组
    async updateTagsGroup(item){
      let dialog = await this.$prompt('请输入分组标题', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.{2,10}?$/,
        inputErrorMessage: '标题只能在2-10个字符之间',
        inputValue: item.title
      });
      if(dialog.action != 'confirm') return;
      let res = await this.$ZGManager.updateMaterial(item.id, {
        title: dialog.value
      });
      if(res.status == 200){
        this.$message.success('修改成功');
        this.getTagsGroup();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  background-color: #fff;
  padding: 15px;
}
.tags-item{
  margin-right: 10px;
  margin-bottom: 10px;
}
.add-group__button{
  float: right;
  margin-bottom: 15px;
}
</style>