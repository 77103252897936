
let templates = [
  {
    id: 'customerLike',
    title: '客户喜好',
    type: 'system',
    config: ['抽烟', '喝酒', '运动', '读书', '旅游', '音乐', '舞蹈', '喝茶', '健身']
  },
  {
    id: 'customerResource',
    title: '客户来源',
    type: 'system',
    config: ['400电话', '名片咨询', '转介绍', '陌生拜访', '电话咨询', '咨询官网', '咨询商品', '微信咨询']
  },
  {
    id: 'customerSex',
    title: '客户性别',
    type: 'system',
    config: ['男', '女']
  },
  {
    id: 'ageGroup',
    title: '年龄段',
    type: 'system',
    config: ['18-25岁', '26-30岁', '31-35岁', '36-40岁', '41-45岁', '46-50岁', '51-55岁', '56-60岁']
  },
  {
    id: 'customerType',
    title: '客户分类',
    type: 'system',
    config: ['已成交', '有意向', '有明确意向', '无意向']
  }
]

export default templates;